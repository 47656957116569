
iframe {
    aspect-ratio: 3 / 2;
    width: 100%;
    height: 100%;
    border: none;
}
.main_swiper{
    position: relative;
}

.swiper {
    width: 100%;
}

.swiper img {
    width: 100%;
    vertical-align: bottom;
}

.swiper-button-next:after,
.swiper-button-prev:after{
    display: none;
}

.swiper-button-next,.swiper-button-prev{
    position: absolute;
    top: calc(50% - 22px);
    z-index: 1;
    cursor: pointer;
}

.swiper-button-next{
    right: -5px;
}
.swiper-button-prev{
    left: -5px;
}

.swiper-button-next img,.swiper-button-prev img{
    width: 45px;
}

.slide_text {
    height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    background: #f2f4f8;
}


.thum_movie{
    position: relative;
}

.thum_movie::before{
    position: absolute;
    content: "";
    background: rgba(0,0,0,.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.thum_movie .icon{
    position: absolute;
    width: 25px;
    top: calc(50% - 7px);
    left: calc(50% - 10px);
}

.thumb_swiper{
    background-color: #f2f4f8;
    padding: 15px 10px;
    /* height: 130px; */
    margin: 20px 0px 10px 0;
    overflow: hidden;
    position: relative;
}
.swiper-thumbs{
    cursor: pointer;
}
.swiper-slide-thumb-active .img{
    outline: 2px solid #3cb4ff;
    outline-offset: -2px;
}
.slide_thumbs_text{
    padding-top: 3px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.swiper-button-thumb-next,.swiper-button-thumb-prev{
    position: absolute;
    top: 30%;
    z-index: 1;
    cursor: pointer;
}

.swiper-button-thumb-next{
    right: -10px;
}
.swiper-button-thumb-prev{
    left: -10px;
}

.swiper-button-thumb-next img,.swiper-button-thumb-prev img{
    width: 35px;
}
