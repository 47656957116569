

.Choumei .clist{
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding-right: 5px;
  cursor: pointer;
}

.Choumei .clist input[type=checkbox] {
  border: 2px solid #555555 !important;
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: -1px 5px 0 0  ;
  border-radius: 3px;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  transition: .3s;
}

.Choumei .clist input[type=checkbox]:checked {
  background: #3CB4FF;
}

.Choumei input[type=checkbox]:checked::before {
  position: absolute;
  padding:2px;
  color: white;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  content: '\f00c';
}


.chomeiTable{
  padding-bottom: 15px;
}
.accordionBtn{
  width: 100%;
  line-height: 25px;
  cursor: pointer;
}
.accordionBtn p{
  position: relative;
  margin: 0;
}
.accordionBtn p::before{
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: calc(100% - 30px);
  z-index: 1;
}
.accordionBtn .aTitle{
  font-size: 13px;
  font-weight: bold;
}
.accordionBtn .acIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 25px;
  width: 25px;
  z-index: 1;
}
.accordionBtn .acIcon img{
  width: 100%;
  transition: all 0.3s;
}
.accordionBtn .acIcon.is-open img{
  transform:rotate(180deg);
}

.chomeiList{
  display: none;
}
.chomeiList.is-open{
  height: 100%;
  display: block;
}