.header{
  /* background-color: #fff; */
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  height: 100%;
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  padding:0 5px 20px 10px;
  box-sizing: border-box;
}
.header .h_top{
  padding: 30px 0 15px;
  text-align: center;
  border-bottom: 1px solid #C4C8CE;
  margin: 0 10px 10px;
}
.header .h_top .logo{
  width: 70px;
}

.header .h_top .site_title{
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}
.header .h_top .users{
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #3CB4FF;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
  cursor: pointer;
}
.header .h_top .users .icon svg{
  font-size: 2rem;
}

.Popup {
  width: 270px;
  border: 1px solid #CCC;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 3px 3px 0 rgba(204, 204, 204, 0.5);
}
.Popup #composition-menu{
  width: 100%;
}
.Popup #composition-menu .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  font-size: 14px;
}

.Popup #composition-menu .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root a{
  text-decoration: none;
  color: #555555;
  width: 100%;
}

.search_box{
  height: calc(100% - 180px);
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}
/* スクロールバーのベース */
.search_box::-webkit-scrollbar {
  width: 8px;
}
.search_box::-webkit-scrollbar-track {
  background-color: #fff;
}
.search_box::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 100px;
}

.header .s_top{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  gap: 10px;
  padding: 10px 0 20px;
}
.header .s_top .icon{
  color: #3CB4FF;
}
.header .s_top .icon svg{
  font-size: 2rem;
}

.header .search_table{
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;

}
.header .search_table .search_inner{
  padding-bottom: 20px;
}
.header .s_title{
  text-align: center;
  font-size: 15px;
  margin: 0 0 10px;
  font-weight: bold;
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;

}
.header .search_list{
  padding-left: 0;
  list-style: none;
}
.header .search_list .list{
  padding-bottom: 10px;
  margin-right: 0;
}

.header .search_list .clist{
  display: flex;
  align-items: center;
}

.header .search_list input[type=checkbox] {
  border: 2px solid #555555 !important;
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: -1px 5px 0 0  ;
  border-radius: 3px;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  transition: .3s;
}

.header .search_list input[type=checkbox]:checked {
  background: #3CB4FF;
}

.header .search_list input[type=checkbox]:checked::before {
  position: absolute;
  padding:2px;
  color: white;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  content: '\f00c';
}

.header .search_list .checktext{
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
  width: calc(100% - 23px);
}

.header .search_list.flex{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}
.header .search_list.madori{
  align-items: center;
}
.header .search_list.flex .list{
  width: 100%;
  flex-basis: 50%;
  font-size: 12px;
}
.header .search_list.madori .list{
  flex-basis: 33.3%;
}



.select {
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  width: 250px;
  appearance: none; /* ① */
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid #C4C8CE; /* ② ここから */
  padding: 10px 25px 10px 10px;
  font-size: 14px;
  cursor: pointer; /* ② ここまで */
  margin-bottom: 10px;
}

.selectFull {
  width: 100%;
}
.select:disabled {
  pointer-events: none;
  background-color: #f1f1f1;
  color: #C4C8CE;
}
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.select-wrapper.small {
  width: 250px;
}
.select-wrapper::after {  /* ③ */
  content: '';
  position: absolute;
  border: 5px solid #C4C8CE;
  border-bottom: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  top: calc(50% - 7px);
  right: 10px;
  width: 0;
  height: 0;
}

.select-wrapper--disabled::after {
  border-top-color: #C4C8CE;
}

.header .searchbtn{
  cursor: pointer;
  text-align: center;
  background-color: #4ab7f4;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  padding: 10px 0;
  border: none;
  /* box-shadow: 2px 2px 5px rgba(0,0,0,0.3); */
}
.header .clearbtn{
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  color: #4ab7f4;
  padding: 10px 0;
  border: 2px solid #4ab7f4;
  /* box-shadow: 2px 2px 5px rgba(0,0,0,0.3); */
}

.end{
  font-size: 10px;
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
  color: gray;
}