.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  font-family:"Noto Sans JP,sans-serif"
}


.lodingbox{
  display: block;
  z-index: 5;
  background-color: #00000070;
  position: fixed;
  width: calc(100% - 300px);
  height: 100vh;
  top: 0;
  right: 0;
}
.lodingbox .inner{
  position: absolute;
  top: 44%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  height: auto;
}

.top_flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}
.top_flex .hyoujibox{
    display: flex;
    align-items: center;
}
.top_flex .hyoujibox .total{
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.top_flex .hyoujibox .total .num{
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #3CB4FF;
  padding-left: 10px;
}
.top_flex .hyoujibox .total .ken{
  color: #3CB4FF;
}

.top_flex .hyouji{
  display: flex;
  padding-left: 30px;
  align-items: flex-end;
} 

.top_flex .hyoujibox .hyouji .text{
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-right: 10px;
}
.top_flex .hyoujibox .hyouji select{
  padding: 5px 10px;
  font-size: 15px;
  border: 2px solid #C4C8CE;
}


.tablebox{
  overflow-y: auto;
  overflow-x: scroll;
  width: auto;
  max-height: 86vh;
  padding-bottom: 10px;
}

/* スクロールバーのベース */
.tablebox::-webkit-scrollbar {
  width: 10px; /* 垂直方向のスクロールバーの幅 */
  height: 10px; /* 水平方向のスクロールバーの幅 */
}
.tablebox::-webkit-scrollbar-track {
  background-color: #fff;
}
.tablebox::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 100px;
}

.tablebox table{
  border-spacing: 0;
  width:max-content;
  border: none;
  /* セルの線を重ねる */
  /* border-collapse:  collapse;  */
  border-collapse: separate
}



.tablebox table tr{
  cursor: pointer;
}
.tablebox table th,
.tablebox table td{
  width: auto;
  /* padding: 5px 10px; */
  padding: 5px;
  height: 50px;
}
.tablebox table th{
  position: sticky;
  top:-1px;
  z-index: 2;
  border: none;
  box-shadow:none;
  line-height: 1.2;
  background-color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
}
.tablebox table td{
  border: 1px solid #C4C8CE;
  box-shadow:none;
  background-color: #FFFFFF;
  text-align: center;
  vertical-align:middle;
}
.tablebox table tr:nth-child(odd) td{
  background-color: #F2F4F8;
}

.tablebox table tr.ON td{
  background-color:#ffe9cd;
}

.tablebox table td.textLeft{
  text-align: left;
}

.tablebox table .kotei{
  position: sticky;
  z-index: 2;
}
/* .tablebox table thead .kotei{
  z-index: 4;
} */
.tablebox table .kotei::before{
  box-sizing: content-box;
}



.sortBtn{
  border: none;
  color: #C4C8CE;
  padding: 0px;
  width: 14px;
  height: 14px;
  text-align: center;
  font-size: 14px;
  margin-left: 3px;
  cursor: pointer;
  background-color: #fff;
}
.mensekiBox{
  display: flex;
  align-items: center;
  height: 100%;
}
.printBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  padding: 4px 0px;
  border: 2px solid #000;
  font-size: 12px;
  background: #fff;
  font-weight: bold;
}

.printBtn:hover{
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .5);
  transition:all 0.2s;
}

/* .printBtn:active{
  opacity: 0.5;  
  transition:all 0.2s;
} */


.detailBtn{
  display: flex;
  align-items: center;
  gap: 3px;
  color: #000;
}
.detailBtn span{
  text-decoration:underline;
}

.detailBtn .tatemonoName{
  margin: 0;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration:underline;
}


.mapBtn{
  width: 40px;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
  text-align: center;
  background: #3CB4FF;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
.mapBtn a{
  color: #fff;
  text-decoration:none;
}


.addressbox{
  display: flex;
  align-items: center;
  gap: 2px;
}

/* .addressbox .address{
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
} */

/* ページネーション */
.pager{
  display:flex;
  margin: 1em 0 0;
  align-items:center;
  justify-content:center;
  width: 100%;
}

.pager .pagenums{
  font-family: Roboto,sans-serif;
  font-size: 14px;
  text-align: center;
  margin: 0 0.2em 0 0.2em;
  cursor: pointer;
  line-height: 25px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}



.top_flex .BtnBox{
    order: -1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.top_flex .BtnBox .btns{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    padding: 10px 15px 5px 10px;
    border-bottom: 2px solid #ccc;
    font-weight: 500;
}
.top_flex .BtnBox .btns.blue{
    border-color: #4ab7f4;
    color: #4ab7f4;
}
.top_flex .BtnBox a.btns{
    text-decoration: none;
    color: #333;
}