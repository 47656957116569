
body{
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
}

main{
  color: #333;
}
.search-details{
  width: 1100px;
  margin: 0 auto;
}

@media screen and (max-width: 1500px) {
  .search-details{
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
  }
}

.campaign-area-div {
  width: 100%;
  margin: 35px auto 0;
}
.campaign-area {
  display: block;
  color: #fff;
  background-color: #ef4778;
  font-size: 30px;
  padding: 20px 40px;
}
.campaign-area span {
  display: block;
  font-size: 14px;
  text-align: right;
  line-height: 20px;
  padding-top: 10px;
}
.campaign-img img{
  width: 100%;
}

.bukken_name{
  margin:35px 0 ;
  text-align: center;
  font-weight: normal;
}

/* .box-first-info{

} */

.firstTable{
  border-spacing: 0;
  width: 100%;
  border: none;
  border-collapse: separate;
  table-layout:fixed;
  border-spacing: 5px;
}
.firstTable th, .firstTable .th, .firstTable td, .firstTable .td{
  box-shadow: none;
}

.firstTable th{
  background-color: #E9ECF3;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}
.firstTable td{
  text-align: center;
  font-size: 14px;
  padding-bottom: 5px;
  letter-spacing: 1px;
}
.firstTable td .num{
  font-size: 18px;
  font-weight: 500;
}
.firstTable td .yachin_num{
  font-size: 30px;
  font-weight: bold;
  color: #EF4778;
}
.firstTable td .large{
  font-size: 20px;
  font-weight: bold;
}



.ImageFlexs{
  display: flex;
  padding: 50px 0;
  gap: 50px;
}
.inner-img-prop{
  width: 60%;
}
.inner-img-floor{
  width: calc(40% - 50px);
}

.inner-img-floor .img-floor{
  margin: 0 auto 20px;
  height: 365px;
  border: 10px solid #f2f4f8;
  position: relative;
}
.inner-img-floor .img-floor .plus{
  position: absolute;
  bottom: 5px;
  right: 10px;
  opacity: 0.6;
}

.inner-img-floor .img-floor a{
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.inner-img-floor .img-floor a img{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.inner-img-floor .pdfBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 15px;
  border: 2px solid #000;
  padding: 15px;
  color: #000;
  font-weight: bold;
  height: 70px;
  transition:all 0.2s;
}
.inner-img-floor .pdfBtn:hover{
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .5);
}

.inner-img-floor .pdfBtn .css-i4bv87-MuiSvgIcon-root{
  font-size: 2.5rem;
}


.inner-img-floor .ButtonBoxs{
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.inner-img-floor .ButtonBoxs .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  gap: 10px;
  border: 2px solid #000;
  padding: 10px 5px;
  color: #000;
  font-weight: bold;
  height: 70px;
  color: #4ab7f4;
  border: 2px solid #4ab7f4;
  transition:all 0.2s;
}
.inner-img-floor .ButtonBoxs .btn:hover{
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .5);
}

.inner-img-floor .ButtonBoxs .btn .css-i4bv87-MuiSvgIcon-root{
  font-size: 2rem;
}



.inner-img-floor .telBox{
  margin-top: 10px;
  background-color: #f2f4f8;
  padding: 20px;
}

.inner-img-floor .telBox p{
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.inner-img-floor .telContact {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  gap: 15px;
  padding-top: 10px;
  font-weight: bold;
}

.inner-img-floor .telContact .css-i4bv87-MuiSvgIcon-root{
  font-size: 2rem;
}

.inner-img-floor .telContact span{
  letter-spacing: 1px;
}
.manager-name{
  font-size: 13px;
}

.modal{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0,0,0,0.8);
  filter: alpha(Opacity=80);
}

.modalBody{
  max-width: 700px;
  margin: 100px auto 0;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  
}
.closeBtnBox{
  position: relative;
  max-width: 700px;
  margin: 0px auto;
}
.closeBtn {
  position: absolute;
  left: 93%;
}
.closeBtn svg{
  font-size: 2em;
}




.search-details .box-btm-info{
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto 70px;
  width: 100%;
  gap: 50px;
}
.search-details .box-btm-info .tbl-prop {
  width: 60%;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}



.search-details .box-btm-info .tbl-prop .item-tbl-prop{
  padding: 7px 11px;
  border-bottom: 1px solid #cccccc;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop.last {
  border-bottom: 0;
  margin-bottom: 10px;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop.ttl{
  background-color: #e9ecf3;
  color: #000000;
  font-size: 18px;
  text-align: justify;
  letter-spacing: .1em;
  padding: 7px 0 7px 12px;
  border-bottom: none;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop.ttl .txt-ttl{
  margin: 0;
}

.search-details .box-btm-info .tbl-prop .item-tbl-prop dl {
  margin: 0;
  display: flex;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop dl dt {
  color: #3cb4ff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.75;
  width: 164px;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop dl dd {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .05em;
  line-height: 1.75;
  overflow: hidden;
  position: relative;
}

.search-details .box-btm-info .tbl-prop .item-tbl-prop dl dd a{
  color: #3cb4ff;
  float: right;
  text-decoration: underline;
}

.search-details .box-btm-info .tbl-prop .item-tbl-prop.facility{
  border-bottom: 0;
  margin-bottom: 0;
  padding: 10px 0;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop .list-facility {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0;
  gap: 10px;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop .list-facility .item-facility {
  font-family: YakuHanJP, 'Noto Sans Japanese', 'Hiragino Kaku Gothic Pro', MyYuGothicM, Meiryo, sans-serif;
  text-align: justify;
  align-items: center;
  justify-content: center;
  background-color: #cccccc;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  letter-spacing: 0;
  line-height: 1.21;
  width: calc(25% - 10px);
  gap: 5px;
}
.search-details .box-btm-info .tbl-prop .item-tbl-prop .list-facility .item-facility.active {
  background-color: #3cb4ff;
}
.search-details .box-btm-info .item-facility img{
  width: 25px;
  height: auto;
}
.search-details .box-btm-info .item-facility.bath img{
  width: 28px;
  height: auto;
}
.search-details .box-btm-info .item-facility.autolock img{
  width: 25px;
  height: auto;
}
.search-details .box-btm-info .item-facility.toilet img{
  width: 20px;
  height: auto;
}
.search-details .box-btm-info .item-facility.air-conditioner img{
  width: 30px;
  height: auto;
}
.search-details .box-btm-info .item-facility.tv img{
  width: 22px;
  height: auto;
}
.search-details .box-btm-info .item-facility.wash img{
  width: 31px;
  height: auto;
}
.search-details .box-btm-info .item-facility.wash img{
  width: 31px;
  height: auto;
}
.search-details .box-btm-info .item-facility.washstand img{
  width: 29px;
  height: auto;
}
.search-details .box-btm-info .item-facility.internet img{
  width: 24px;
  height: auto;
}
.search-details .box-btm-info .item-facility.rice img{
  width: 26px;
  height: auto;
}
.search-details .box-btm-info .item-facility.stove img{
  width: 30px;
  height: auto;
}




.search-details .box-btm-info .box-outline {
  background-color: #f2f4f8;
  padding: 53px 30px;
  position: relative;
  width: calc(40% - 50px);
}
.search-details .box-btm-info .box-outline .ttl-outline {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.54;
  margin: 0 0 10px;
}
.search-details .box-btm-info .box-outline .txt-outline {
  font-size: 13px;
  line-height: 1.84;
  white-space: pre-line
}


.mapBox{
  width: 100%;
  padding-bottom: 100px;
}
.mapBox .tab-area{
  border-bottom: 5px solid #3cb4ff;
}
.mapBox .tab-area .tab-map{
  display: flex;
  align-items: center;
  background: #3cb4ff;
  border-color: #3cb4ff;
  color: #ffffff;
  font-size: 22px;
  height: 57px;
  justify-content: center;
  letter-spacing: .1em;
  margin: 0 4px;
  text-align: center;
  width: 300px;
  border-radius: 10px 10px 0 0;
}

.mapBox .tab-area .tab-map img{
  width: 49px;
  height: auto;
  margin-right: 5px;
}


.wrapper {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.tatemono {
  background-color: #000000;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  /* padding-bottom:-2px; */
  border-radius: 15px;
  color: white;
  /* margin: 5px auto; */
  margin-left:10px;
  margin-top:10px;
  padding-top:4px;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}







.pin2 {
  width: 35px;
  height: 55px;
  height: auto;
  text-align: center;
  vertical-align: middle;
}
.pinComment{
    background-color: rgb(29, 62, 251);
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding:4px;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 20px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}


#MapBtns{
  display: flex;
  padding-top: 20px;
}
#MapBtns .mapbtns{
  padding: 5px;
  width: 12.5%;
}
#MapBtns .mapbtns img{
  width: 100%;
}

.copyright {
  background-color: #555555;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.14em;
  padding: 11px 0;
  text-align: center;
  margin: 0;
}




.wrapper {
  margin: 0px;
  padding: 0px;
    position: relative;
    width: 20px;
    height: 35px;
}

.tatemono {
  background-color: #000000;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  /* padding-bottom:-2px; */
  border-radius: 15px;
  color: white;
  /* margin: 5px auto; */
  margin-left:10px;
  margin-top:10px;
  padding-top:4px;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}






.pin {
    background-color: rgb(50, 158, 65);
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 10px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}

/* .pin:after {
    position: absolute;
    content: '';
    border-top: 15px solid rgb(31, 24, 100);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0px;
    top: 15px;
    left: 15px;
} */

.pin span {
    line-height: 25px;
    text-align: center;
    vertical-align: central;
    /* vertical-align: middle; */
}
.pinline {
    background-color: rgb(252, 142, 79);
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 10px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}

.pin2 {
    background-color: rgb(11, 184, 175);
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding:4px;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 20px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}
.pinComment{
    background-color: rgb(29, 62, 251);
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding:4px;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 20px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}