.Termsofuse{
    width: 1100px;
    margin: 50px auto;
}
@media screen and (max-width: 1500px) {
    .Termsofuse {
        width: 100%;
        margin: 50px auto;
        padding: 0 50px;
    }
}

.message{
    padding: 20px 0 50px;
    font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
}
.message .title_style1{
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}
.message .title_style1-sub{
    font-size: 13px;
    margin-bottom: 20px;
    text-align: center;
}

.inner {
    margin: 0 0 10%;
    font-size: 15px;
    line-height: 1.8;
    text-align: left;
}

.ijo{
    text-align: right;
    padding-top: 50px;
    font-size: 14px;
}

.copyright {
    background-color: #555555;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.14em;
    padding: 11px 0;
    text-align: center;
    margin: 0;
}