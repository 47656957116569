
body{
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
}

main{
  color: #333;
}
.login{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.login .login_inner{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  /* width: 600px; */
  max-width: 700px;
  height: 82%;
  text-align: center;
}

.login_top{
  padding-bottom: 70px;
}

.login_top .logo{
  width: 100px;
}

.login_top .site_title{
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 0;
}

.login_body p{
  font-size: 16px;
  margin-top: 0;
}

.inputbox{
  margin-bottom: 15px;
  text-align: left;
}

.inputbox p{
  font-size: 14px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 16px !important;
}

.textbox {
  width: 100%;
  border-radius: 5px;
  border: 2px solid #C4C8CE;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  font-size: 16px;
}
.textbox:focus {
  outline: 0;
  border: 2px solid #3CB4FF;
}

.pass_forget_link{
  font-size: 16px;
  color: #3CB4FF;
  text-decoration: underline;
}
.pass_forget_link p{
  font-size: 16px;
  margin: 0;
  margin: 10px 0 0px;
}

.btnBox{
  margin-top: 20px;
}
.loginBtn_b{
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #3CB4FF;
  padding: 20px;
  cursor: pointer;
}
.loginBtn_disable{
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #CCCCCC;
    padding: 20px;
}
.loginBtn_h{
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #3CB4FF;
  border: 2px solid #3CB4FF;
  background-color: #fff;
  padding: 20px;
  cursor: pointer;
}


.ninshoInput{
  width:10%;
  text-align:center;
  height:100px;
  border: 0;
  border-bottom: 2px solid #000;
  margin-right: 10px;
  font-size: 30px;
  font-weight: bold;
}

.ninshoInput:focus {
  outline: 0;
  border: 0;
  border-bottom: 2px solid #3CB4FF;
}


.error{
  text-align: left;
  color: red;
  font-weight: bold;
}


.TermsofuseOver{
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

/* スクロールバーのベース */
.TermsofuseOver::-webkit-scrollbar {
  width: 8px;
}
.TermsofuseOver::-webkit-scrollbar-track {
  background-color: #fff;
}
.TermsofuseOver::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 100px;
}