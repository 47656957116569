#MAP{
    font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
}
.map_head{
    display: flex;
    justify-content: space-between;
    padding: 10px 50px 10px 30px;
    background-color: #fff;
    width: 100%;
    height: 70px;
}

.map_head .BtnBox{
    display: flex;
    align-items: center;
    gap: 10px;
}

.map_head .BtnBox .btns{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    padding: 10px 15px 5px 10px;
    border-bottom: 2px solid #ccc;
    font-weight: 500;
}
.map_head .BtnBox .btns.blue{
    border-color: #4ab7f4;
    color: #4ab7f4;
}
.map_head .BtnBox a.btns{
    text-decoration: none;
    color: #333;
}

.map_head .total{
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: auto;
}
.map_head  .total .num{
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #3CB4FF;
    padding-left: 10px;
}
.map_head  .total .ken{
    color: #3CB4FF;
}


.MapArea{
    width: calc(100% - 300px);
    height: calc(100% - 70px); 
    position: absolute;
    top: 70px;
    left: 300px;
}


.side-property-area{
    height: calc(100vh - 105px);
    background: #fff;
    /* border-radius: 10px;
    box-shadow: 5.12px 5.12px 5.12px rgba(0,0,0,0.1); */
    height: 100%;
    margin: 10px 0px 0 auto;
    padding: 11px 10px 20px;
    position: relative;
    z-index: 1;
    width: 320px;
}

.btn-close{
    height: 35px;
    position: absolute;
    top: -5px;
    left: -10px;
    z-index: 9;
    width: 35px;
}

.btn-close img {
    border: 0;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    vertical-align: bottom;
    width: auto;
}

.side-property{
    padding: 0 10px 0px
}
.side-property-img {
    width: 278px;
    height: 185px;
}

.side-property-area .side-property-container {
    border-bottom: 1px solid #dcdcdc;
}

.side-property-area .container-ttl{
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 5px;
}

.side-property-area .container-txt {
    border-top: 1px solid #dcdcdc;
    font-size: 13px;
    letter-spacing: 0.05em;
    padding: 9px 0;
    margin: 0;
}

.side-property-area .container-txt>span {
    color: #3cb4ff;
    display: inline-block;
    margin-right: 9px;
}

.side-property-area .list-property-scroll {
    max-height: calc(100vh - 425px);
    width: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px 0px;
}

/* スクロールバーのベース */
.side-property-area .list-property-scroll::-webkit-scrollbar {
    width: 10px; /* 垂直方向のスクロールバーの幅 */
    height: 10px; /* 水平方向のスクロールバーの幅 */
}
.side-property-area .list-property-scroll::-webkit-scrollbar-track {
    background-color: #fff;
}
.side-property-area .list-property-scroll::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 100px;
}

.side-property-area .list-property {
    width: auto;
    margin: 0;
    padding-left: 0;
    list-style: none;
}




.list-property__item{
    border-bottom: 1px solid #dcdcdc;
    padding: 5px 0;
}

.list-property__item a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #555;
}

.list-property__item a:hover{
    background-color:#ffe9cd ;
}

.list-property__item .madori{
    width: 35%;
    padding-left: 5px;
}
.list-property__item .madori img{
    max-width: 100%;
    max-height: 100%;
}

.list-property__item .room-data{
    width: 65%;
    line-height: 1.4;
    font-size: 14px;
    letter-spacing: 0;
    text-align: left;
    padding: 15px 20px 5px 5px;
    position: relative;
}

.list-property__item .room-data p{
    margin: 0;
}

.list-property__item .room-data .arrow{
    width: 10px;
    position: absolute;
    top: calc(50% - 10px);
    right: 5px;
}


.list-property__item span {
    font-size: inherit;
}

.list-property__item .room-data .num-price {
    font-family: "DIN Next LT Pro Regular";
    color: #ed3232;
    font-size: 24px;
    font-weight: 700;
}
.list-property__item .room-data .shiki-icon {
    margin-right: 10px;
}

.list-property__item .room-data .rei-icon,
.list-property__item .room-data .shiki-icon {
    padding-left: 25px;
    position: relative;
    margin-bottom: 7px;
}

.list-property__item .room-data .rei-icon:before,
.list-property__item .room-data .shiki-icon:before {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    background: #f2f4f8;
    color: #333;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
}

.list-property__item .room-data .rei-icon:before{
    content: "礼";
}
.list-property__item .room-data .shiki-icon:before{
    content: "敷";
}

.gm-style iframe + div { 
    border:none!important;
}